$('.arrow-down').on('click', function () {
    $(this).hide();
    $('.arrow-up').show();
    $('.user_child').hide();
})
$('.arrow-up').on('click', function () {
    $(this).hide();
    $('.arrow-down').show();
    $('.user_child').show();
})

$('select[name="start_station"]').on('change', function () {
    if ($(this).val() !== '') {
        $.ajax({
            type: 'GET',
            url: '/search-station-route',
            data: {
                start_station: $(this).val(),
                route_id: new URLSearchParams(window.location.search).get('route_id'),
                search_by_route: $('#searchByRoute').val()
            },
            dataType: 'json',
            success: data => {
                $('select[name="stop_station"]').empty();
                $('select[name="stop_station"]').append('<option hidden="true" disabled selected>--選択してください--</option>');
                for (const k in data) {
                    $('select[name="stop_station"]').append('<option value=' + data[k].id + ' >' + data[k].title + (Boolean(data[k].is_clone_station) ? '（戻り）' : '' ) + '</option>');
                }
            }
        });
    }
})
